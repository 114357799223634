import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import Popup from "./modal/Popup";
import { MyTextInput } from "./input";
import Languages from "@/commons/Languages";
import { APi, BUTTON_STYLES, INPUT_FIELDS, config } from "@/commons/Constant.ts";
import { Button } from "./button";
import Validate from "@/utils/Validate";
import { useBaseService } from "@/utils/BaseServices";
import { toast } from "react-toastify";
import { fiedlsCreatePage } from "@/commons/FieldsDataObj";
import PackageProduct from "@/pages/Createpage/PackageProduct";
import { RadioButton } from "./RadioButton";
import ReferCodePopup from "@/pages/Createpage/ReferCodePopup";
import { Payment } from "./Payment";
import axios from "axios";


export const InformationSevices = forwardRef(
    (
        {
        },
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            show,
            hide,
            handlegetId
        }));

        const refModal = useRef(null)
        const refPayment = useRef(null)

        const [values, setValues] = useState(fiedlsCreatePage)

        const [getId, setGetId] = useState('')

        const [pointer, setPointer] = useState(false)
        const [codeinvite, setCodeinvite] = useState('')
        const [percentOff, setPercentOff] = useState(0)
        const [dataPackage, setDataPackage] = useState([])

        const [amountPackage, setAmountPackage] = useState(0)
        const [namePackage, setNamePackage] = useState(0)

        const [provinces, setProvinces] = useState([]);
        const [districts, setDistricts] = useState([]);
        const [wards, setWards] = useState([]);
        const [selectedProvince, setSelectedProvince] = useState('');
        const [selectedDistrict, setSelectedDistrict] = useState('');
        const [selectedWard, setSelectedWard] = useState('');

        const [radioDataPackage, setRadioDataPackage] = useState('none')

        const [messageCodeInvite, setMessageCodeInvite] = useState('')
        const [valuedataAnotherTotalPrice, setValuedataAnotherTotalPrice] = useState(0)

        const refUnderfine = useRef(null)
        const refConfirmName = useRef(null)
        const refConfirmPhone = useRef(null)
        const packageProductRef = useRef()
        const referCodePopupRef = useRef()
        const refCodeinvite = useRef(null)

        const { get, post } = useBaseService()

        const show = () => {
            refModal?.current?.showModal();
        }

        const hide = () => {
            refModal?.current?.hideModal()
        }

        const handlegetId = (id) => {
            setGetId(id)
        }

        useEffect(() => {
            const asyncListProduct = async () => {
                const response = await get(APi.listProduct, config)
                setDataPackage(response.data)
            }

            asyncListProduct()
        }, [])

        useEffect(() => {
            // Gọi API để lấy danh sách tỉnh
            axios.get('https://vapi.vnappmob.com/api/province/')
                .then((response) => {
                    setProvinces(response.data.results);
                })
                .catch((error) => {
                    console.error('Error fetching provinces:', error);
                });
        }, []);


        useEffect(() => {
            const discount = parseInt((1 - percentOff) * 100)
            const total =
                parseInt(amountPackage) * (discount / 100)

            setValuedataAnotherTotalPrice(total)
        }, [percentOff, amountPackage]);

        const handleProvinceChange = (e) => {
            const provinceId = e.value;
            setSelectedProvince(provinceId);

            // Gọi API để lấy danh sách quận/huyện theo tỉnh đã chọn
            axios.get(`https://vapi.vnappmob.com/api/province/district/${provinceId}`)
                .then((response) => {
                    setDistricts(response.data.results);
                    setWards([]);
                })
                .catch((error) => {
                    console.error('Error fetching districts:', error);
                });
        };

        useEffect(() => {
            values.confirmProvince = provinces.find((p) => p.province_id === selectedProvince)?.province_name;
            values.confirmDistrict = districts.find((d) => d.district_id === selectedDistrict)?.district_name;
            values.confirmWardt = wards.find((w) => w.ward_id === selectedWard)?.ward_name;

        }, [selectedProvince, selectedDistrict, selectedWard]);

        const handleDistrictChange = (e) => {
            const districtId = e.value;
            setSelectedDistrict(districtId);

            // Gọi API để lấy danh sách phường/xã theo quận/huyện đã chọn
            axios.get(`https://vapi.vnappmob.com/api/province/ward/${districtId}`)
                .then((response) => {
                    setWards(response.data.results);
                })
                .catch((error) => {
                    console.error('Error fetching wards:', error);
                });
        };

        const handleWardChange = (e) => {
            setSelectedWard(e.value);
        };

        const optionListProvinces = provinces.map(function (item) {
            return { value: item?.province_id, label: item?.province_name }
        });

        const optionListDistrict = districts.map(function (item) {
            return { value: item?.district_id, label: item?.district_name }
        });

        const optionListWards = wards.map(function (item) {
            return { value: item?.ward_id, label: item?.ward_name }
        });


        const onChangeText = useCallback(
            (e, name) => {
                switch (name) {
                    case INPUT_FIELDS.isUseBanking:
                        values.arraylist[0].isUseBanking = e
                        setValues((prevValues) => {
                            const newArray = [...prevValues.arraylist]
                            newArray[0]['isUseBanking'] = e
                            return {
                                ...prevValues,
                                arraylist: newArray,
                            }
                        })
                        break

                    case INPUT_FIELDS.isUseGuestBook:
                        setValues((prevValues) => ({
                            ...prevValues,
                            isUseGuestBook: e,
                        }))
                        values.isUseGuestBook = e
                        setPointer(!pointer)
                        break

                    case INPUT_FIELDS.isUseConfirm:
                        setValues((prevValues) => ({
                            ...prevValues,
                            isUseConfirm: e,
                        }))
                        values.isUseConfirm = e
                        break

                    case INPUT_FIELDS.password:
                        values.password = e
                        setValues((prevValues) => ({
                            ...prevValues,
                            password: e,
                        }))
                        break

                    case INPUT_FIELDS.isEffectOfOpenning:
                        values.isEffectOfOpenning = e
                        setValues((prevValues) => ({
                            ...prevValues,
                            isEffectOfOpenning: e,
                        }))
                        break

                    case INPUT_FIELDS.confirmName:
                        values.confirmName = e
                        setValues((prevValues) => ({
                            ...prevValues,
                            confirmName: e,
                        }))
                        break

                    case INPUT_FIELDS.confirmPhone:
                        values.confirmPhone = e
                        setValues((prevValues) => ({
                            ...prevValues,
                            confirmPhone: e,
                        }))
                        break

                    default:
                        break
                }
            },
            [values, setPointer, pointer, setCodeinvite]
        )

        const onKeyPress = useCallback(() => {
            return
        }, [])

        const renderInput = useCallback(
            (
                ref,
                label,
                placehodel,
                name,
                type,
                maxLength,
                isIcon,
                icon,
                inputStyle,
                value
            ) => {
                return (
                    <div className='item_field_single'>
                        <MyTextInput
                            ref={ref === '' ? refUnderfine : ref}
                            name={name}
                            placeHolder={placehodel}
                            type={type}
                            maxLength={maxLength}
                            isIcon={isIcon}
                            icon={icon}
                            styleGroup={'man_inputStyle'}
                            onChangeText={(e) => onChangeText(e.target.value, name)}
                            onKeyPress={onKeyPress}
                            inputStyle={inputStyle}
                            value={value}
                        />
                    </div>
                )
            },
            [onKeyPress, onChangeText]
        )

        const renderRadio = useCallback((id, label, value, onChange, name, isSelected) => {
            return (
                <div className='options_select'>
                    <RadioButton
                        id={id}
                        label={label}
                        value={value}
                        onChange={onChange}
                        name={name}
                        isSelected={isSelected === value}
                    />
                </div>
            )
        }, [])

        const onChangeCodePress = useCallback(
            async (e) => {
                setCodeinvite(e)
                const response = await post(APi.codeSale, { code: e }, config)
                if (JSON.stringify(response.data) === JSON.stringify({})) {
                    refCodeinvite?.current?.setErrorMsg(Languages.errorMsg.errorCode)
                    setMessageCodeInvite('')
                    setPercentOff(0)
                } else {
                    setTimeout(() => {
                        refCodeinvite?.current?.setNoErrorMsg()
                        setCodeinvite(response.data.code)
                        setMessageCodeInvite(Languages.errorMsg.correctCode)
                        setPercentOff(response.data.percentOff)
                    }, 1000)
                }
            },
            [setMessageCodeInvite, setCodeinvite, refCodeinvite?.current?.setErrorMsg()]
        )

        const radioChangeHandler = (e) => {
            setRadioDataPackage(e.target.value)
            values.effectImage = e.target.value
            const { id, name } = e.target
            setAmountPackage(id)
            setNamePackage(name)
            const discount = parseInt((1 - percentOff) * 100)
            const total =
                parseInt(id) * (discount / 100)

            setValuedataAnotherTotalPrice(total)
        }


        const onChangeModalConfirmPayment = useCallback(async () => {
            if (radioDataPackage === 'none')
                toast.error('Vui lòng chọn gói sản phẩm', {
                    autoClose: 1000,
                    closeButton: false
                })
            else {
                const jsonData = {
                    "_id": getId,
                    "status": '4',
                    "productId": radioDataPackage,
                    "codeInvite": codeinvite,
                }

                const response = await post(APi.updateInvitation, jsonData, config)

                if (response.errorCode == 0) {
                    const jsonPaymentData = {
                        "_id": getId,
                        "status": '4',
                        "confirmName": values.confirmName,
                        "confirmPhone": values.confirmPhone,
                        "confirmProvince": values.confirmProvince,
                        "confirmDistrict": values.confirmDistrict,
                        "confirmWard": values.confirmWardt,
                        "totalAmount": valuedataAnotherTotalPrice,
                    }
        
                    const paymentResponse = await post(APi.updateInvitation, jsonPaymentData, config)
        
                    if (paymentResponse.errorCode == 0) {
                        toast.success(Languages.errorMsg.updatesuccess)
                        hide()
                        refPayment?.current?.show()
                        refPayment?.current?.handlegetId(getId)
                        refPayment?.current?.handleggetAmount(valuedataAnotherTotalPrice)
                    } else {
                        toast.error(Languages.errorMsg.errorSuccess)
                    }
                } else {
                    toast.error(Languages.errorMsg.errorSuccess, {
                        autoClose: 1000,
                        closeButton: false
                    })
                }
            }
        }, [values, valuedataAnotherTotalPrice, hide, radioDataPackage, getId, codeinvite, amountPackage])

        const renderContentModal = useMemo(() => {
            return <>
                {
                    <div className='renderContentModal text-left'>
                        <div className='form_confirm_info'>
                            <div className='header text-uppercase'>
                                <h2 style={{ textTransform: 'uppercase' }}>Các gói sản phẩm</h2>
                            </div>
                            <div className='body_form'>
                                <div className='wrap_form'>
                                    <div className="head_option">
                                        <h4>Chọn gói sản phẩm muốn mua</h4>
                                        <div
                                            className=' ml-4 font-bold w-7 text-center text-lg  h-7 rounded-full border-2 border-b-text cursor-pointer'
                                            onClick={() => packageProductRef.current.showModal()}
                                        >
                                            ?
                                        </div>
                                    </div>
                                    <Popup
                                        ref={packageProductRef}
                                        // height={'75vh'}
                                        content={<PackageProduct />}
                                        maxWidth={1400}
                                    />
                                    <div className="group_options">
                                        {
                                            dataPackage.map(function (item, index) {
                                                return <div className={`group__items ${radioDataPackage === item._id ? 'active' : ''}`} key={index}>
                                                    {renderRadio(
                                                        item.amount,
                                                        item.name,
                                                        item._id,
                                                        radioChangeHandler,
                                                        item.name,
                                                        radioDataPackage,
                                                    )}
                                                </div>
                                            })

                                        }
                                    </div>
                                </div>
                                <div className='wrap_form'>
                                    <div className="head_option">
                                        <h4>Mã giới thiệu</h4>
                                        <div
                                            className=' ml-4 font-bold w-7 text-center text-lg  h-7 rounded-full border-2 border-b-text cursor-pointer'
                                            onClick={() => referCodePopupRef.current.showModal()}
                                        >
                                            ?
                                        </div>
                                    </div>
                                    <Popup ref={referCodePopupRef} content={<ReferCodePopup />} />
                                    <div className='wrap_package_referralcode'>
                                        <div className='fullwidth_input_colum'>
                                            <div className='single_hor_input'>
                                                <MyTextInput
                                                    ref={refCodeinvite}
                                                    value={codeinvite}
                                                    name={INPUT_FIELDS.referralCode}
                                                    placeHolder={'Nhập ' + Languages.text.referralCode}
                                                    type={'text'}
                                                    maxLength={20}
                                                    styleGroup={'man_inputStyle'}
                                                    onChangeText={(e) => onChangeCodePress(e.target.value)}
                                                    disabled={messageCodeInvite.length > 0 ? false : true}
                                                />
                                                <div className='messageSuccess'>
                                                    <p>{messageCodeInvite}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form_confirm_info'>
                            <div className='header'>
                                <h2>{Languages.text.confimSuccess}</h2>
                            </div>
                            <div className='body_form'>
                                <div className='wrap_form'>
                                    <h4>{Languages.text.ReceiverPerson}</h4>
                                    <div className='form_group_info'>
                                        <div className='double_input_row'>
                                            <div className='half_row_hor_input'>
                                                {renderInput(
                                                    refConfirmName,
                                                    '',
                                                    Languages.inputText.name,
                                                    INPUT_FIELDS.confirmName,
                                                    'text',
                                                    100,
                                                    false,
                                                    '',
                                                    '',
                                                    values.confirmName
                                                )}
                                            </div>
                                            <div className='half_row_hor_input'>
                                                {renderInput(
                                                    refConfirmPhone,
                                                    '',
                                                    Languages.inputText.phone,
                                                    INPUT_FIELDS.confirmPhone,
                                                    'number',
                                                    10,
                                                    false,
                                                    '',
                                                    '',
                                                    values.confirmPhone
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='body_info_price'>
                                <div className='bode_info_head'>
                                    <h4>{Languages.text.servicesInfo}</h4>
                                </div>
                                <div className='body_info_list_product'>
                                    <div className='package_box'>
                                        <div className='box_left'>
                                            <h5>{Languages.text.packageSer}</h5>
                                            <p>{namePackage}</p>
                                        </div>
                                        <div className='box_right'>
                                            <h5>{Validate.formatMoney(amountPackage)}</h5>
                                        </div>
                                    </div>
                                    <div className='package_box'>
                                        <div className='box_left'>
                                            <h5>{Languages.text.referralCode}</h5>
                                            <p>{codeinvite}</p>
                                        </div>
                                        <div className='box_right'>
                                            <h5>{parseInt(percentOff * 100)}%</h5>
                                        </div>
                                    </div>
                                    <div className='total_price'>
                                        <h5>{Languages.text.total}</h5>
                                        <span className='amount'>
                                            {Validate.formatMoney(valuedataAnotherTotalPrice)}
                                        </span>
                                    </div>
                                    <p className='free14day'>{Languages.text.free14day}</p>
                                    <div className='bottom_form_btn_success'>
                                        <p className='des_pay_services'>
                                            {Languages.text.payServices}
                                        </p>
                                        <p className='contact'>
                                            Zalo: 0933619010 - Hotline: (+84) 933619010
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button
                            label={'Tiếp tục'}
                            autocenter
                            buttonStyle={BUTTON_STYLES.PINK}
                            textStyle={BUTTON_STYLES.WHITE}
                            onPress={onChangeModalConfirmPayment}
                        />
                    </div>
                }
            </>
        }, [
            codeinvite,
            messageCodeInvite,
            dataPackage,
            onChangeCodePress,
            radioDataPackage,
            namePackage,
            amountPackage,
            values,
            valuedataAnotherTotalPrice,
            percentOff,
            renderInput,
            optionListWards,
            optionListProvinces,
            optionListDistrict,
            handleProvinceChange,
            handleDistrictChange,
            handleWardChange
        ])

        const renderModal = useMemo(() => {
            return (
                <Popup
                    ref={refModal}
                    content={renderContentModal}
                    maxWidth={1000}
                />
            )
        }, [renderContentModal])

        return <>{renderModal} <Payment ref={refPayment} /></>;
    });
