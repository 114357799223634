const Languages = {
  inputText: {
    username: 'Email / Số điện thoại',
    password: 'Mật khẩu',
    forgotPass: 'Quên mật khẩu?',
    or: 'HOẶC',
    continueWithFB: 'Tiếp tục với Facebook',
    continueWithGG: 'Tiếp tục với Google',
    youNotAccount: 'Bạn không có tài khoản? Đăng ký',
    youhaveAccount: 'Bạn đã có tài khoản? Đăng nhập',
    backIndex: 'Quay lại trang đăng nhập',
    recoveryPwd: 'Khôi phục mật khẩu',
    sendEmailRecoveryPwd: 'Gửi email khôi phục mật khẩu',
    name: 'Họ và tên',
    phone: 'Số điện thoại',
    phonepla: 'Nhập số điện thoại',
    pwd: 'Mật khẩu',
    firstNameGroom: 'Họ chú rể',
    firstNameBride: 'Họ cô dâu',
    firstName: 'Họ',
    firstAnother: 'Họ đệm',
    nameGroom: 'Tên chú rể',
    nameBride: 'Tên cô dâu',
    namesingle: 'Tên',
    stt: 'Thứ bậc trong gia đình',
    codeArea: 'Mã vùng',
    groom: 'Chú rể:',
    bride: 'Cô dâu:',
    father: 'Bố:',
    mother: 'Mẹ:',
    top1: 'Trưởng Nam',
    top1woman: 'Trưởng Nữ',
    notTop: 'Thứ',
    death: 'Đã mất',
    contentInvite: 'Nhập nội dung lời mời',
    address: 'Địa chỉ',
    note: 'Ghi chú thêm (Ví dụ: Giao giờ hành chính)'
  },
  common: {
    cancel: 'Hủy bỏ',
    OK: 'Chọn',
    back: 'Quay lại',
    yes: 'Có',
    no: 'Không',
    close: 'Đóng',
    search: 'Tìm kiếm',
    agree: 'Đồng ý',
    or: 'Hoặc',
    continue: 'Tiếp tục',
    saveDraf: 'Lưu bản nháp',
    pay: 'Kích hoạt thiệp cưới',
    listLetter: 'Danh sách thiệp'
  },
  buttonText: {
    createTC: 'Tạo thiệp cưới',
    anyMore: 'Tìm hiểu thêm',
    tryIt: 'Trải nghiệm ngay!',
    scanQr: 'Quét mã thanh toán QR ',
    seeAll: 'Xem tất cả',
    createTypeTC: 'Tạo mẫu thiệp cưới',
    seeBefore: 'Xem trước',
    copylink: 'Link chia sẻ',
    payment: 'Trả phí',
    expire: 'Hết hạn',
    dowloadTc: 'Tải thiệp cưới',
    dowloadClient: 'Tải danh sách khách',
    checkGuest: 'Lời chúc',
    delete: 'Xoá thiệp',
    edit: 'Chỉnh sửa',
    invitationTemplate: 'Lời mời mẫu',
    titleTemplate: 'Tiêu đề mẫu',
    saveSettings: 'LƯU CÀI ĐẶT',
    success: 'Xác nhận',
    svDetails: 'Xem chi tiết dịch vụ',
    progessing: 'Đang tiến hành'
  },
  errorMsg: {
    noInternet: 'Kết nối bị gián đoạn, vui lòng thử lại!',
  },
  menu: {
    home:'Trang chủ',
    services: 'Dịch vụ',
    customerCare: 'Chăm sóc khách hàng',
    myPage: 'Trang của tôi',
    login: 'Đăng nhập',
    register: 'Đăng ký',
    verifyOtp: 'Xác thực OTP',
    yourPage: 'Đám cưới của bạn',
    commentPage: 'Danh sách lời chúc',
    createPage: 'Tạo thiệp',
    recoveryPwd: 'Quên mật khẩu',
    notfound: 'Không tìm thấy trang',
    emailOtp: 'Xác thực Email',
    editorPage: 'Chỉnh sửa thiệp'
  },
  text: {
    verifyHeader: 'Xác thực OTP gửi về Email',
    textBanner: 'Chia sẻ khoảnh khắc đặc biệt trong cuộc sống!',
    textTinhte: '"Chạm tinh tế - Khắc thương yêu"',
    textHeadTC:
      'Tạo thiệp dễ dàng, chia sẻ nhanh chóng, & sửa đổi không giới hạn!',
    showFullIf: 'Hiển thị đầy đủ thông tin:',
    address: 'Địa điểm diễn ra lễ cưới',
    albumWed: 'Album ảnh cưới (tối đa 15 ảnh)',
    listCustomer: 'Danh sách khách mời',
    effectOpen: 'Hiệu ứng mở thiệp đẹp & chuyên nghiệp',
    contentEffect:
      'Khi Đám cưới di động được mở, Hiệu ứng hoạt hình được hiển thị trên toàn màn hình Và làm cho sự chú ý của khách!',
    timeWork: '09h Sáng - 18h Chiều / Thứ 2 - Thứ 6',
    supportCustormer: 'Hỗ trợ khách hàng',
    bankInf: 'Thông tin ngân hàng',
    typeDs: 'Chọn mẫu thiệp của bạn',
    createaWeddingYourOwn: 'Tạo mẫu cưới của riêng bạn',
    managerTc: 'Quản lý thiệp cưới',
    seeTC: 'Xem thiệp ',
    status: 'Trạng thái',
    date: 'Ngày cưới',
    manager: 'Quản lý',
    free: 'Miễn phí',
    draffversion: 'Bản nháp',
    autoDelete: '(Tự động xoá sau 24 ngày)',
    onlyDate: '(Còn 30 ngày)',
    complete: 'Đã thanh toán',
    notrushpay: 'Đừng vội thanh toán!',
    freeDraft: 'Hãy tạo bản nháp miễn phí.',
    beauToday:
      'Vào ngày đẹp nhất trong cuộc đời bạn, Hãy tạo thiệp cưới Online với Cuoithoi',
    hot: 'HOT',
    packageServices: 'Các gói dịch vụ',
    only: 'Chỉ với',
    price: '600.000đ',
    packAllDs: 'trọn gói thiết kế',
    mUse: '3 tháng sử dụng',
    savePen: 'Lưu bút',
    album: 'Album ảnh (15 ảnh)',
    confirmInvite: 'Xác nhận lời mời',
    bigsize: '(Kích thước khuyến nghị 1024x768px)',
    smallsize: '(Kích thước khuyến nghị 1024x1024px)',
    addonepic: 'Thêm một hình ảnh',
    chooseCoverImage: 'Chọn ảnh bìa',
    chooseThumbs: 'Chọn ảnh thumbnail',
    effectImage: 'Chọn khung cho ảnh:',
    logout: 'Đăng xuất',
    hello: 'xin chào, ',
    logoutSure: 'Bạn có chắc chắn muốn đăng xuất tài khoản?',
    inforWed: 'Thông tin của lễ cưới',
    man: 'Nhà trai',
    women: 'Nhà gái',
    explanatory: 'Biểu đạt giải thích “Cố”',
    chrysanthemumIcon: 'Hiển thị biểu tượng hoa Cúc',
    createAfter: 'Tạo thiệp cưới sau',
    contentAfter:
      'Bạn muốn tạo thiệp cưới vào lúc khác? Các thiết lập hiện tại sẽ không được lưu.',
    timeAndLocation: 'Thời gian và địa điểm',
    invite: 'Lời mời:',
    inviteLanguage: 'Chọn mẫu lời mời',
    inviteTitle: 'Chọn mẫu tiêu đề',
    displayModeDeceased: 'Chế độ hiển thị với người đã mất: ',
    timeadnlocation: 'Thời gian và địa điểm',
    wedding: 'lễ cưới',
    timer: 'Thời gian',
    place: 'Địa điểm',
    placeWedding: 'Địa chỉ (Ví dụ: Sảnh A, tầng 2, số 117 Trần Duy Hưng)',
    namePlaceWed: 'Tên địa điểm (Khách sạn, nhà riêng,...)',
    mapPlaceWedding: 'Liên kết địa điểm lễ cưới',
    displayDateCoundown: 'Hiển thị Thời gian đếm ngược đến ngày cưới',
    titleCountdownTemp: 'Nhập nội dung tiêu đề đếm ngược thời gian',
    daringAndFlirting: 'Dạm ngõ & Ăn hỏi',
    Egagement: 'Dạm ngõ',
    interrogation: 'Ăn hỏi',
    placeEagement: 'Địa điểm dạm ngõ',
    placeInterrogation: 'Địa điểm ăn hỏi',
    sortImage: 'Bạn có thể kéo ảnh và thay đổi thứ tự',
    maximumUpload: 'Số lượng tối đa là 15 ảnh, kích thước tối đa một ảnh lên đến 30 MB.',
    performance:
      'Nếu bạn tải lên nhiều ảnh, trên một số thiết bị, tốc độ sẽ chậm.',
    linkVideo: 'Liên kết video',
    weddingProgram: 'Chương trình',
    video: 'Video',
    welcomeGuest: 'Đón khách',
    celebrate: 'Làm lễ',
    dinner: 'Tiệc tối',
    music: 'Âm nhạc',
    warnning: 'Lưu ý',
    contentWarnning: 'Nội dung lưu ý',
    informationBanking: 'thông tin ngân hàng',
    accountHolder: 'chủ tài khoản',
    serinumber: 'số tài khoản',
    qrcode: 'Tải lên QR Code',
    confirmAttend: 'Xác nhận tham dự ',
    useFeatureAttend: 'Bạn có muốn sử dụng tính năng Xác nhận tham dự không?',
    useFeatureVideo: 'Bạn có muốn sử dụng tính năng video không?',
    useFeatureEvent: 'Bạn có muốn sử dụng tính năng Chương trình không?',
    useFeatureDamNgo: 'Bạn có muốn sử dụng tính năng Dặm ngõ và Ăn hỏi không?',
    useBanking: 'Bạn có muốn sử dụng tính năng Gửi chúc phúc không?',
    use: 'Sử dụng',
    attend: 'Tính năng',
    enablevideo: 'Hãy gửi video của bạn cho Cưới Thôi và để biến khoảnh khắc quý giá đó thành một thiệp cưới độc đáo và ấn tượng nhé.',
    enableAttend:
      'cho phép khách mời xác nhận có tham dự đám cưới hay không. Chỉ có cô dâu và chú rể xem được tình trạng tham dự và số liệu thống kê khách mời.',
    readChart:
      'Cách đọc số liệu thống kê: Nếu đăng nhập bằng tài khoản đã tạo thiệp cưới và nhấn vào xem thiệp cưới, bạn sẽ thấy nút “Xem trạng thái”.',
    guestbook: 'Lưu bút',
    useGuestbook: 'Bạn có muốn sử dụng lưu bút không?',
    obligatory: 'Mật khẩu là bắt buộc khi bạn tải xuống và xóa.',
    settingPwd: 'Cài đặt mật mã',
    minPwd: 'Mật mã cài đặt tối thiểu 8 ký tự.',
    startEffect: 'Hiệu ứng mở đầu',
    checkedUseStartEffect: 'Bạn có muốn sử dụng hiệu ứng mở đầu không?',
    textStyleInvitation: 'Thiết kế nền',
    textStyleFont: 'kiểu chữ',
    chooseInvitationStyle: 'Chọn thiết kế nền:',
    chooseFontTitle: 'Chọn kiểu chữ tiêu đề:',
    chooseFontContent: 'Chọn kiểu chữ nội dung:',
    effectBg: 'Hiệu ứng nền',
    typeBg: 'Chọn kiểu nền:',
    colorBg: 'Chọn màu nền:',
    effectBg: 'Chọn hiệu ứng',
    packageProduct: 'Chọn Gói Sản Phẩm Bạn Muốn Mua',
    packagePro: 'Chọn gói sản phẩm muốn mua',
    anotherPro: 'Sản phẩm khác',
    referralCode: 'Mã giới thiệu',
    changePlan: 'Có thể thay đổi plan theo ý muốn',
    useMax7day: 'Người dùng miễn phí được sử dụng dịch vụ tối đa 7 ngày',
    useActive90day: 'Người dùng trả phí được sử dụng dịch vụ tối đa 90 ngày',
    contactSupport: 'Liên hệ chăm sóc khách hàng để được phục vụ tốt hơn',
    nologin: 'Vui lòng đăng nhập',
    nologinContent: 'Vui lòng đăng nhập hoặc đăng ký tài khoản để thực hiện tính năng này. Xin cảm ơn',
    managerletter: 'Quản lý thiệp cưới',
    productNumber: 'Product Number',
    customURL: 'Custom URL',
    success: 'Thành Công',
    happysuccess: 'Xin chúc mừng bạn đã tạo thiệp cưới thành công',
    waring: 'Cảnh báo',
    outPageCreate: 'Hãy nhớ lưu tất cả các thiếp lập của bạn trước khi rời trang để tránh mất thông tin! ',
    confimSuccess: 'Xác nhận thông tin',
    ReceiverPerson: 'Thông tin người nhận',
    servicesInfo: 'Thông tin dịch vụ',
    packageSer: 'Gói dịch vụ',
    total: 'Tổng',
    free14day: '(Bạn có thể sử dụng miễn phí thiệp trong vòng 7 ngày)',
    payServices: 'Để thanh toán dịch vụ, vui lòng liên hệ tới bộ phận chăm sóc khách hàng',
    draff: 'Lưu bản nháp thành công',
    noneToken: 'Phiên đăng nhập hết hạn vui lòng đăng nhập lại để sử dụng tính năng này',
    noletter: 'Vui lòng tạo thiệp trước',
    noletterContent: 'Vui lòng tạo thiệp trước khi thực hiện tính năng này. Xin cảm ơn',
    mobileWeddingCard: 'Thiệp cưới di động của riêng bạn Chỉ với 5 phút, tạo thiệp mời thật dễ dàng!',
    aFewClicks: 'Hãy lựa chọn mẫu thiệp, tải lên ảnh cưới và chỉnh sửa nội dung chỉ với vài thao tác nhấn chuột đơn giản.',
    bank: 'Chọn ngân hàng',
    deleteItem: 'Bạn có chắc muốn xóa',
    deleteContent: 'Bấm đồng ý để tiếp tục xóa',
    paymentSuccess: 'Đã nhận thông tin',
    alertSendPayment: 'Cuoithoi đã nhận được thông tin thanh toán và sẽ gửi thư xác nhận tới email của bạn.',
    contactnow: 'Liên hệ ngay cho chúng tôi khi cần hỗ trợ',
    limit: 'Vượt quá số lượng',
    contentLimit: 'Số lượng thiệp miễn phí đã được tạo tối đa',
    accountGroom: 'Tài khoản nhà trai',
    accountBride: 'Tài khoản nhà gái',
    noFields: '(Không bắt buộc)',
    coppyMaps: '*Sao chép liên kết địa điểm lễ cưới trên trang Google Maps và liên kết phải có thông số tọa độ',
    togetherCreate: 'Cùng tạo Thiệp Cưới cho ngày trọng đại của bạn nhé!'
  },
  errorMsg: {
    noCorect: 'Không hợp lệ',
    noInternet: 'Kết nối bị gián đoạn, vui lòng thử lại!',
    sessionExpired: 'Kết nối bị gián đoạn, vui lòng thử lại!',
    userNameRequired: 'Họ và tên không được để trống',
    required: 'không được để trống',
    userNameLength: 'Họ và tên không được ít hơn 8 ký tự',
    userNameRegex: 'Không được chứa ký tự đặc biệt hoặc số',
    errIllegal: 'Mật khẩu không hợp lệ',
    emailNull: 'Email không được để trống',
    emailRegex: 'Email không đúng định dạng',
    cardNull: 'Số CMND/CCCD không được để trống',
    cardRegex: 'Số CMND/CCCD phải là số',
    cardCheck: 'Số CMND/CCCD không hợp lệ',
    pwdNull: 'Mật khẩu không được để trống',
    pwdCheck: 'Mật khẩu không được ít hơn 8 ký tự',
    conFirmPwd: 'Xác nhận mật khẩu không trùng khớp với mật khẩu',
    emptyAmount: 'Bạn chưa nhập số tiền',
    minAmount: 'Số tiền nạp/rút tối thiểu là 100.000 vnđ',
    phoneIsEmpty: 'Số điện thoại không được để trống',
    phoneRegex: 'Số điện thoại không đúng định dạng',
    phoneCount: 'Số điện thoại chỉ được 10 số',
    jobEmpty: 'Nghề nghiệp không được để trống',
    taxIdEmpty: 'Mã số thuế không được để trống',
    taxIdSyntax: 'Mã số thuế không được ít hơn 10 ký tự',
    specialCharacters: 'Không được chứa kí tự đặc biệt',
    birthdayEmpty: 'Ngày sinh ',
    birthdayNotNumber: 'Ngày sinh không đúng định dạng ví dụ: 1970-01-01',
    birthdayAge18: 'Chưa đủ 18 tuổi',
    birthdayAge95: 'Vượt quá 95 tuổi',
    errProvince: 'Thành phố không được bỏ trống',
    errDistrict: 'Quận huyện không được bỏ trống',
    errWard: 'Xã phường không được bỏ trống',
    errFunds: 'Nguồn tiền không được bỏ trống',
    errMoveto: 'Chuyển tới không được bỏ trống',
    errKeyBook: 'Mã sổ không được bỏ trống',
    errMsgOldPwdCompare: 'Mật khẩu cũ bạn nhập không đúng',
    errMsgCurrentPwdCompare: 'Mật khẩu nhập lại không trùng khớp',
    errMsgEmpty: 'Mật khẩu nhập không được để trống',
    errMsgLength: 'Mật khẩu nhập phải từ 8 kí tự trở lên',
    errMsgSpecialChar: 'Mật khẩu nhập không được chứa kí tự đặc biệt',
    errMsgSpaceChar: 'Mật khẩu nhập không được chứa kí tự trống',
    errMsgUpperChar: 'Mật khẩu phải có 1 kí tự in hoa',
    errMsgGender: 'Giới tính không được bỏ trống',
    uploading: 'Đang tải ảnh lên',
    uploadingError: 'Tải ảnh thất bại',
    uploadingEmpty: 'Thiếu ảnh. Vui lòng tải ảnh lên!',
    noEmpty: 'Chúng tôi cần thêm thông tin',
    errStk: 'Số tài khoản hoặc số thẻ vượt quá giới hạn cho phép',
    errStkEmpty: 'Số tài khoản hoặc số thẻ trống',
    errNameEmpty: 'Tên tài khoản ngân hàng trống',
    errBankEmpty: 'Ngân hàng chưa được chọn',
    errMethodMoney: 'Vui lòng cập nhật phương thức nhận tiền',
    errMethodWithdrawMoney: 'Vui lòng cập nhật phương thức rút tiền',
    errChooseMethodMoney: 'Vui lòng chọn phương thức nhận tiền',
    errNoEnoughMoney: 'Hạn mức giao dịch tối thiểu <br />100.000 VND',
    errLimit: 'Số dư hiện tại không đủ để giao dịch',
    updatePwd: 'Xác nhận thay đổi mật khẩu thành công',
    errEmptyBirthday: '01/01/1920',
    errKYC: 'Vui lòng xác thực CMT/CCCD để thực hiện việc rút tiền',
    notKYc: ' chưa xác thực CMT/CCCD',
    errorSuccess: 'Hệ thống quá tải vui lòng thử lại trong ít phút nữa',
    success: 'Quá trình tạo thiệp cưới thành công. Ấn tiếp tục để kích hoạt',
    updatesuccess: 'Cập nhật thiệp cưới thành công.',
    nocreaptePage: 'Hệ thống đang tải hoặc chưa có thiệp nào được khởi tạo',
    errorCode: 'Mã giới thiệu không chính xác',
    correctCode: 'Mã giới thiệu được áp dụng',
    expireRequest: 'Yêu cầu kích hoạt thành công. Chúng tôi sẽ phản hồi lại trong thời gian sớm nhất',
  },
}

export default Languages
