import React from 'react'

const ReferCodePopup = () => {
  return (
    <div className=' text-text '>
      Nhập mã giới thiệu của cưới thôi, để tận hưởng mức giá ưu đãi nhé!!!
    </div>
  )
}

export default ReferCodePopup
