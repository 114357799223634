import React, { useRef } from 'react'
import msgCmtTitle from '@/assets/home-image/msgCmtTitle.png'
import closeIcon from '@/assets/svg/icon-close-outline.svg'
import { convertTimeFormat } from '@/utils/helpers'
import Popup from '@/components/modal/Popup'
import DeleteCmtInput from './DeleteCmtInput'
const WeddingCmt = ({ viewDetail, cmt, deleteCmt, index, handleDeleteCmt }) => {
  const modalRef = useRef()
  return (
    <div
      className={`${!viewDetail && 'max-w-md'} p-4 relative`}
      style={{ width: '100%' }}
    >
      <img
        className='close_icon'
        onClick={() => modalRef.current.showModal()}
        src={closeIcon}
        alt=''
        // className=' w-6 '
        style={{
          width: '28px',
          position: 'absolute',
          right: '24px',
          top: '24px',
          cursor: 'pointer',
          zIndex: '10 !important',
        }}
      />
      <div
        className='p-4 rounded-lg'
        style={{ background: 'rgba(238, 241, 239, 0.5)', width: '100%' }}
      >
        <img
          src={msgCmtTitle}
          alt=''
          className='w-4'
          style={{ width: '90px', margin: 'auto' }}
        />

        <p className='text-text text-lg p-4'>{cmt.desWish}</p>

        <div>
          <h2 className='text-base font-medium'>-{cmt.namePeopleSend}-</h2>
          {/* <p className='text-base font-light'>
            {convertTimeFormat(cmt.createTime)}
          </p> */}
        </div>
      </div>
      <Popup
        ref={modalRef}
        content={
          <DeleteCmtInput
            deleteCmt={deleteCmt}
            _id={cmt._id}
            handleCloseModal={() => modalRef?.current.hideModal()}
            handleDeleteCmt={handleDeleteCmt}
            index={index}
          />
        }
      />
    </div>
  )
}

export default WeddingCmt
