import React from 'react'

const TitleDescribeIcon = () => {
  return (
    <svg
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.86453 9.38197C10.0634 9.93841 11.6993 8.66977 12.4405 8.09476C13.8069 7.03439 15.4292 5.00737 14.8723 3.80392C14.5358 3.07678 13.7041 3.27328 12.8697 2.08749C12.3612 1.36488 12.4667 1.00313 12.0117 0.728751C11.2583 0.274629 10.0974 0.739617 9.43728 1.22951C9.25663 1.36307 8.45252 1.99196 8.007 3.73238C7.59181 5.35147 7.30386 8.65709 8.86453 9.38197Z'
        fill='#91ADA3'
        fillOpacity='0.5'
      />
      <path
        d='M8.8646 9.38197C9.37034 8.70192 9.01084 7.52202 8.29231 5.16267C7.69556 3.20401 7.13912 2.57784 6.86203 2.30211C6.46631 1.90775 5.65405 1.09821 4.43023 1.08644C3.18423 1.07421 1.87891 1.89236 1.56967 2.94594C1.24957 4.03574 2.10982 4.98564 1.64121 5.44882C1.24549 5.84046 0.434594 5.35645 0.210929 5.66343C-0.0584658 6.03288 0.744284 7.24674 1.71275 8.09522C3.92087 10.0285 7.81826 10.7887 8.8646 9.38197Z'
        fill='#91ADA3'
        fillOpacity='0.5'
      />
      <path
        d='M8.86457 9.38197C7.76572 7.84303 3.99284 8.89163 1.99887 10.0973C0.920835 10.7493 0.498859 11.3646 0.282438 11.8853C-0.120069 12.8547 -0.148593 14.3135 0.568585 14.8889C0.927626 15.1773 1.33692 15.1334 1.92733 15.6758C2.23974 15.9624 2.27324 16.11 2.42809 16.2481C3.13757 16.8788 5.13742 16.1802 6.43323 15.1755C8.28956 13.7353 9.85658 10.771 8.86457 9.38197Z'
        fill='#91ADA3'
        fillOpacity='0.5'
      />
      <path
        d='M14.1573 17.1057C13.7385 17.3746 12.8954 16.4863 12.0117 16.8911C11.7314 17.0197 11.6241 17.1967 11.4394 17.3918C10.6375 18.238 9.05239 18.703 8.14958 18.3213C6.48069 17.6164 6.89135 13.9137 6.93391 13.5297C7.02537 12.7062 7.34502 9.81845 8.86494 9.38198C10.6099 8.88032 12.7143 11.9605 13.5135 13.5297C14.3108 15.0959 14.6413 16.7946 14.1573 17.1057Z'
        fill='#91ADA3'
        fillOpacity='0.5'
      />
      <path
        d='M8.86473 9.38197C8.59171 11.0943 12.4755 13.8526 14.872 13.101C15.6209 12.866 15.9238 12.3833 17.3749 11.9568C18.4289 11.6471 19.0714 11.6662 19.2344 11.2415C19.3901 10.8358 18.9138 10.5302 18.519 9.52505C18.0658 8.3705 18.4054 8.03817 18.0898 7.52247C17.2667 6.17777 13.8596 6.64502 11.5818 7.52247C10.1805 8.06217 9.00282 8.51584 8.86473 9.38197Z'
        fill='#91ADA3'
        fillOpacity='0.5'
      />
    </svg>
  )
}

export default TitleDescribeIcon
