import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/index.css'
// import './style.scss'
import App from '@/App'
import { store } from '@/store'
import { Provider } from 'react-redux'
// import snowImg from './assets/home-image/snow.png'
// const snowImg = document.createElement('img')
// snowImg.src = './assets/home-image/snow.png'
// const images = [snowImg]
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
