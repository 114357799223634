import React from 'react'

const VolumeMute = () => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M56 48C56 52.4183 52.4183 56 48 56L0 56L4.89568e-06 -4.89568e-06L48 -6.99382e-07C52.4183 -3.13124e-07 56 3.58172 56 8L56 48Z'
        fill='#A7BDB5'
        fillOpacity='0.6'
      />
      <path
        d='M39.25 39.375L14.5 14.625'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M27.1092 15.9511C26.8285 15.7997 26.5105 15.7313 26.1924 15.7539C25.8742 15.7766 25.5691 15.8894 25.3127 16.079L25.2663 16.1149L23.261 17.7567C23.2002 17.8065 23.1504 17.8684 23.115 17.9386C23.0796 18.0088 23.0593 18.0856 23.0554 18.1641C23.0515 18.2426 23.0641 18.3211 23.0923 18.3944C23.1206 18.4677 23.1639 18.5343 23.2195 18.5899L27.0396 22.41C27.1183 22.4887 27.2185 22.5424 27.3277 22.5641C27.4369 22.5859 27.5501 22.5747 27.6529 22.5321C27.7557 22.4895 27.8436 22.4173 27.9054 22.3248C27.9672 22.2322 28.0002 22.1233 28.0001 22.012V17.4776C28.0032 17.1664 27.9221 16.8602 27.7652 16.5914C27.6084 16.3227 27.3817 16.1014 27.1092 15.9511V15.9511Z'
        fill='white'
      />
      <path
        d='M27.6716 32.5751L16.7985 21.7048C16.5876 21.4938 16.3016 21.3751 16.0033 21.375H13.9375C13.4899 21.375 13.0607 21.5528 12.7443 21.8693C12.4278 22.1857 12.25 22.6149 12.25 23.0625V30.9375C12.25 31.3851 12.4278 31.8143 12.7443 32.1307C13.0607 32.4472 13.4899 32.625 13.9375 32.625H18.8425L25.3113 37.9202C25.5709 38.1129 25.8806 38.2266 26.2032 38.2477C26.5259 38.2688 26.8477 38.1963 27.1302 38.0391C27.3971 37.8869 27.6184 37.6662 27.7714 37.3998C27.9243 37.1334 28.0032 36.831 28 36.5238V33.3696C28.0001 33.0717 27.882 32.786 27.6716 32.5751V32.5751Z'
        fill='white'
      />
      <path
        d='M34.7496 27C34.7496 25.2731 34.3411 23.6341 33.5015 21.9888C33.435 21.8562 33.3428 21.7381 33.2304 21.6412C33.118 21.5444 32.9875 21.4707 32.8465 21.4245C32.7055 21.3783 32.5568 21.3604 32.4088 21.3719C32.2609 21.3834 32.1167 21.4241 31.9846 21.4916C31.8524 21.559 31.7349 21.652 31.6388 21.765C31.5427 21.8781 31.47 22.0091 31.4247 22.1504C31.3794 22.2917 31.3626 22.4406 31.3751 22.5884C31.3876 22.7363 31.4293 22.8802 31.4976 23.0119C32.1719 24.3323 32.4996 25.6373 32.4996 27C32.4996 27.1875 32.4923 27.3781 32.4778 27.5716C32.4718 27.6523 32.4832 27.7333 32.5114 27.8092C32.5395 27.885 32.5837 27.9539 32.6409 28.0111L33.6506 29.0208C33.7235 29.0939 33.8152 29.1455 33.9155 29.17C34.0158 29.1944 34.1209 29.1908 34.2193 29.1595C34.3177 29.1282 34.4056 29.0704 34.4733 28.9925C34.541 28.9146 34.586 28.8195 34.6033 28.7177C34.7002 28.1503 34.7491 27.5757 34.7496 27V27Z'
        fill='white'
      />
      <path
        d='M39.2504 27C39.2504 23.4014 38.3307 21.1015 36.8471 18.5583C36.6947 18.3047 36.4486 18.1214 36.1621 18.048C35.8755 17.9745 35.5716 18.0169 35.316 18.166C35.0605 18.315 34.874 18.5587 34.7968 18.8442C34.7196 19.1298 34.758 19.4343 34.9037 19.6917C36.2319 21.9685 37.0004 23.8943 37.0004 27C37.0004 28.6756 36.7691 30.015 36.3416 31.2645C36.3072 31.3641 36.3015 31.4713 36.3251 31.574C36.3487 31.6767 36.4007 31.7707 36.4751 31.8453L37.3519 32.7235C37.4182 32.7898 37.5001 32.8386 37.59 32.8652C37.6799 32.8919 37.7751 32.8956 37.8668 32.8762C37.9586 32.8567 38.044 32.8145 38.1153 32.7536C38.1866 32.6927 38.2416 32.6149 38.2751 32.5273C38.8988 30.9206 39.2504 29.198 39.2504 27Z'
        fill='white'
      />
      <path
        d='M43.7496 27C43.7496 21.7793 42.33 18.4844 40.1981 15.1446C40.0375 14.8928 39.7836 14.7152 39.492 14.6507C39.2005 14.5862 38.8952 14.6402 38.6435 14.8008C38.3918 14.9613 38.2141 15.2153 38.1496 15.5069C38.0851 15.7984 38.1391 16.1036 38.2997 16.3554C40.2213 19.3626 41.4996 22.3242 41.4996 27C41.4996 30.2477 40.9069 32.644 39.9225 34.7716C39.8743 34.8759 39.8591 34.9924 39.8791 35.1056C39.8991 35.2187 39.9533 35.323 40.0343 35.4044L40.8724 36.2426C40.9358 36.307 41.0137 36.3552 41.0997 36.3831C41.1856 36.411 41.277 36.4178 41.3661 36.4029C41.4552 36.388 41.5395 36.3518 41.6116 36.2975C41.6838 36.2431 41.7419 36.1722 41.7808 36.0907C43.159 33.2508 43.7496 30.4453 43.7496 27Z'
        fill='white'
      />
    </svg>
  )
}

export default VolumeMute
