import { MyTextInput } from "@/components/input";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import Languages from '@/commons/Languages'
import { SelectInvitationTemplate, SelectPropagateTemplate, fiedlsCreatePage } from "@/commons/FieldsDataObj";
import { BUTTON_STYLES, CheckParams, Convert, NAME_INPUT_BRIDE, itemLocal } from "@/commons/Constant.ts";
import { RadioButton } from "@/components/RadioButton";
import IcChrysanthemum from '@/assets/home-image/IcChrysanthemum.svg'
import { MyTextArea } from "@/components/textarea";
import { Button } from "@/components/button";
import { useMemo } from "react";
import Popup from "@/components/modal/Popup";
import TitleCreate from "@/components/createPage/subcomp/TitleCreate";
import FormValidate from "@/utils/FormValidate";
import { getItemFromLocalStorage } from "@/utils/localStorage";
import Ic_Bride from '@/assets/home-image/Ic_Bride.png'
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { ImageUpload } from "@/components/imageUpload";
import ImgUploadIcon from "@/components/icons/ImgUploadIcon";
import { uploadImage } from "@/utils/axios";

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ size: [] }],
        [{ font: [] }],
        [{ align: ["right", "center", "justify"] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ color: ["red", "blue", "Green", "Yellow", "Orange", "Purple", "Pink", "Brown"] }],
        [{ background: ["red", "blue", "Green", "Yellow", "Orange", "Purple", "Pink", "Brown"] }]
    ]
};

const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font"
];

const FamilyBride = forwardRef(({ props, idCreateRespon }, ref) => {

    useImperativeHandle(ref, () => ({

        onChangeCreatLetter

    }));

    const [value, setValue] = useState(fiedlsCreatePage)
    const [radioDead, setRadioDead] = useState('none')
    const [inviteTemp, setInviteTemp] = useState('')
    const [propagate, setPropagate] = useState('')

    const [checkParams, setCheckParams] = useState(CheckParams.AFFTER)
    const [radioInviteTemplate, setRadioInviteTemplate] = useState('none')
    const [radioPropagateTemplate, setRadioPropagateTemplate] = useState('none')

    const refModal = useRef(null)
    const refUnderfine = useRef(null)
    const refFirstnNameBride = useRef(null)
    const refMiddleNameBride = useRef(null)
    const refNameBride = useRef(null)
    const refPhoneBride = useRef(null)

    const refFirstnNameFather = useRef(null)
    const refMiddleNameFather = useRef(null)
    const refNameFather = useRef(null)
    const refPhoneFather = useRef(null)

    const refFirstnNameMother = useRef(null)
    const refMiddleNameMother = useRef(null)
    const refNameMother = useRef(null)
    const refPhoneMother = useRef(null)

    const refinvite = useRef(null)

    const [imagesCover, setImagesCover] = useState([])

    const itemLocal = getItemFromLocalStorage('createLeter')

    useEffect(() => {

        if (itemLocal) {
            itemLocal.informationOfBride.firstName && (value.informationOfBride[0].firstName = itemLocal.informationOfBride.firstName)
            itemLocal.informationOfBride.middleName && (value.informationOfBride[0].middleName = itemLocal.informationOfBride.middleName)
            itemLocal.informationOfBride.name && (value.informationOfBride[0].name = itemLocal.informationOfBride.name)
            itemLocal.informationOfBride.phoneNumberOfBride && (value.informationOfBride[0].phoneNumberOfBride = itemLocal.informationOfBride.phoneNumberOfBride)
            itemLocal.informationOfBride.firstFatherNameOfBride && (value.informationOfBride[0].firstFatherNameOfBride = itemLocal.informationOfBride.firstFatherNameOfBride)
            itemLocal.informationOfBride.middleFatherNameOfBride && (value.informationOfBride[0].middleFatherNameOfBride = itemLocal.informationOfBride.middleFatherNameOfBride)
            itemLocal.informationOfBride.fatherNameOfBride && (value.informationOfBride[0].fatherNameOfBride = itemLocal.informationOfBride.fatherNameOfBride)
            itemLocal.informationOfBride.phoneNumberOfFatherBride && (value.informationOfBride[0].phoneNumberOfFatherBride = itemLocal.informationOfBride.phoneNumberOfFatherBride)
            itemLocal.informationOfBride.isGoneFatherBride && (value.informationOfBride[0].isGoneFatherBride = itemLocal.informationOfBride.isGoneFatherBride)
            itemLocal.informationOfBride.firstMotherNameOfBride && (value.informationOfBride[0].firstMotherNameOfBride = itemLocal.informationOfBride.firstMotherNameOfBride)
            itemLocal.informationOfBride.middleMotherNameOfBride && (value.informationOfBride[0].middleMotherNameOfBride = itemLocal.informationOfBride.middleMotherNameOfBride)
            itemLocal.informationOfBride.motherNameOfBride && (value.informationOfBride[0].motherNameOfBride = itemLocal.informationOfBride.motherNameOfBride)
            itemLocal.informationOfBride.phoneNumberOfMotherBride && (value.informationOfBride[0].phoneNumberOfMotherBride = itemLocal.informationOfBride.phoneNumberOfMotherBride)
            itemLocal.informationOfBride.isGoneMotherOfBride && (value.informationOfBride[0].isGoneMotherOfBride = itemLocal.informationOfBride.isGoneMotherOfBride)
            itemLocal.weddingVow && (value.informationOfBride[0].weddingVow = itemLocal.weddingVow)
            itemLocal.imgWeddingVow && (value.informationOfBride[0].imgWeddingVow = itemLocal.imgWeddingVow)
            itemLocal.contentOfInvitation && (value.informationOfBride[0].contentOfInvitation = itemLocal.contentOfInvitation)
            itemLocal.contentOfInvitation && (setInviteTemp(itemLocal.contentOfInvitation))
            itemLocal.isDisplayGonePeople && (setRadioDead(itemLocal.isDisplayGonePeople))
            itemLocal.isDisplayGonePeople && (setRadioDead(itemLocal.isDisplayGonePeople))
            itemLocal.weddingVow && (setPropagate(itemLocal.weddingVow))
        } else {
            value.informationOfBride[0].firstName = ''
            value.informationOfBride[0].middleName = ''
            value.informationOfBride[0].name = ''
            value.informationOfBride[0].phoneNumberOfBride = ''
            value.informationOfBride[0].firstFatherNameOfBride = ''
            value.informationOfBride[0].middleFatherNameOfBride = ''
            value.informationOfBride[0].fatherNameOfBride = ''
            value.informationOfBride[0].phoneNumberOfFatherBride = ''
            value.informationOfBride[0].isGoneFatherBride = ''
            value.informationOfBride[0].firstMotherNameOfBride = ''
            value.informationOfBride[0].middleMotherNameOfBride = ''
            value.informationOfBride[0].motherNameOfBride = ''
            value.informationOfBride[0].phoneNumberOfMotherBride = ''
            value.informationOfBride[0].isGoneMotherOfBride = ''
            value.informationOfBride[0].contentOfInvitation = ''
            value.informationOfBride[0].weddingVow = ''
        }

    }, [])

    const renderRadio = useCallback(
        (id, label, value, onChange, isSelected) => {

            return (
                <div className='options_select'>
                    <RadioButton
                        id={id}
                        label={label}
                        value={value}
                        onChange={onChange}
                        isSelected={isSelected === value}
                    />
                </div>
            )
        },
        []
    )

    const onSortEnd = useCallback((oldIndex, newIndex) => {
        setImagesCover((array) => arrayMove(array, oldIndex, newIndex))
    }, [])

    const onChangeCoverImage = (imageList) => {
        setImagesCover(imageList)
        if (imageList.length > 0) {
            imageList.slice(-1).map(function (item) {
                return uploadImage(item.file)
                    .then((response) => {
                        value.informationOfBride[0].imgWeddingVow = response.data.data
                    })
                    .catch((error) => {
                        toast.error(error)
                    })
            })
        }
    }

    const onChangeCreatLetter = useCallback(() => {

        const errMsgFirstNameB = FormValidate.inputNameEmpty(value.informationOfBride[0].firstName, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgMiddleNameB = FormValidate.inputNameEmpty(value.informationOfBride[0].middleName, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgNameB = FormValidate.inputNameEmpty(value.informationOfBride[0].name, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgPhoneB = FormValidate.passConFirmPhone(value.informationOfBride[0].phoneNumberOfBride)

        const errMsgFirstNameFather = FormValidate.inputNameEmpty(value.informationOfBride[0].firstFatherNameOfBride, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgMiddleNameFather = FormValidate.inputNameEmpty(value.informationOfBride[0].middleFatherNameOfBride, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgNameFather = FormValidate.inputNameEmpty(value.informationOfBride[0].fatherNameOfBride, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgPhoneFather = FormValidate.passConFirmPhone(value.informationOfBride[0].phoneNumberOfFatherBride)

        const errMsgFirstNameMother = FormValidate.inputNameEmpty(value.informationOfBride[0].firstMotherNameOfBride, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgMiddleNameMother = FormValidate.inputNameEmpty(value.informationOfBride[0].middleMotherNameOfBride, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgNameMother = FormValidate.inputNameEmpty(value.informationOfBride[0].motherNameOfBride, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)
        const errMsgPhoneMother = FormValidate.passConFirmPhone(value.informationOfBride[0].phoneNumberOfMotherBride)

        // const errMsgInvite = FormValidate.inputNameEmpty(value.informationOfBride[0].contentOfInvitation, Languages.errorMsg.required, Languages.errorMsg.userNameRegex)

        // refinvite.current?.setErrorMsg(errMsgInvite)

        // refFirstnNameMother.current?.setErrorMsg(errMsgFirstNameMother)
        // // refMiddleNameMother.current?.setErrorMsg(errMsgMiddleNameMother)
        // refNameMother.current?.setErrorMsg(errMsgNameMother)
        // refPhoneMother.current?.setErrorMsg(errMsgPhoneMother)

        // refFirstnNameFather.current?.setErrorMsg(errMsgFirstNameFather)
        // // refMiddleNameFather.current?.setErrorMsg(errMsgMiddleNameFather)
        // refNameFather.current?.setErrorMsg(errMsgNameFather)
        // refPhoneFather.current?.setErrorMsg(errMsgPhoneFather)


        // refFirstnNameBride.current?.setErrorMsg(errMsgFirstNameB)
        // // refMiddleNameBride.current?.setErrorMsg(errMsgMiddleNameB)
        // refNameBride.current?.setErrorMsg(errMsgNameB)
        // refPhoneBride.current?.setErrorMsg(errMsgPhoneB)

        if (`${errMsgFirstNameB}${errMsgNameB}${errMsgPhoneB}${errMsgFirstNameFather}${errMsgNameFather}${errMsgPhoneFather}${errMsgFirstNameMother}${errMsgNameMother}${errMsgPhoneMother}${errMsgMiddleNameB}${errMsgMiddleNameFather}${errMsgMiddleNameMother}`.length === 0) {
            return true
        }
        return false

    }, [value])

    const onChangeText = useCallback((e, name) => {

        switch (name) {

            case NAME_INPUT_BRIDE.firstName:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['firstName'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.middleName:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['middleName'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.name:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['name'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.phoneNumberOfBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['phoneNumberOfBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.firstFatherNameOfBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['firstFatherNameOfBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.middleFatherNameOfBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['middleFatherNameOfBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.fatherNameOfBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['fatherNameOfBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.phoneNumberOfFatherBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['phoneNumberOfFatherBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.isGoneFatherBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['isGoneFatherBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.firstMotherNameOfBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['firstMotherNameOfBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.middleMotherNameOfBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['middleMotherNameOfBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.motherNameOfBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['motherNameOfBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.phoneNumberOfMotherBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['phoneNumberOfMotherBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            case NAME_INPUT_BRIDE.isGoneMotherOfBride:
                setValue(prevValues => {
                    const newArray = [...prevValues.informationOfBride];
                    newArray[0]['isGoneMotherOfBride'] = e;
                    return {
                        ...prevValues,
                        informationOfBride: newArray
                    };
                });
                break

            default:
                break
        }


    }, [value, setValue]);

    const radioChangeHandlerInviteTemplate = (text, values) => {
        setRadioInviteTemplate(values)
        setInviteTemp(text)
        value.informationOfBride[0].contentOfInvitation = text
    }

    const radioChangeHandlerPropagateTemplate = (text, values) => {
        setRadioPropagateTemplate(values)
        setPropagate(text)
        value.informationOfBride[0].weddingVow = text
    }

    const renderMapRadio = useCallback((title, data, radioChangeHandlerTemplate, selected) => {

        return <div className='section_choose_template'>
            <div className='head_template'>
                <h3>
                    {title}
                </h3>
            </div>
            <div className='group_radio_choose_template'>

                {data.map((item, index) => (
                    <div className='SelectInvitationTemplate_map' key={index}>
                        {renderRadio(item.value, item.text, item.value, () => radioChangeHandlerTemplate(item.text, item.value), selected)}
                    </div>
                ))}

            </div>
        </div>

    }, [])

    const renderContentModal = useMemo(() => {

        return (
            (checkParams === CheckParams.INVITE_TEMPLATES && renderMapRadio(Languages.text.inviteLanguage, SelectInvitationTemplate, radioChangeHandlerInviteTemplate, radioInviteTemplate)) ||
            (checkParams === CheckParams.PROPAGATE && renderMapRadio(Languages.text.inviteLanguage, SelectPropagateTemplate, radioChangeHandlerPropagateTemplate, radioPropagateTemplate))
        )
    }, [
        checkParams,
        renderMapRadio,
        radioChangeHandlerInviteTemplate,
        radioChangeHandlerPropagateTemplate
    ])

    const renderModal = useMemo(() => {

        return (
            <Popup
                ref={refModal}
                content={renderContentModal}
                btnCancelText={Languages.common.cancel}
                btnSubmitText={Languages.common.agree}
                maxWidth={checkParams === CheckParams.AFFTER ? Convert.W_400 : Convert.W_800}
            />
        )
    }, [renderContentModal, checkParams])

    const onChangeOpenInviteTemplate = () => {
        setCheckParams(CheckParams.INVITE_TEMPLATES)
        refModal.current?.showModal();
    }

    const onChangeOpenPropagateTemplate = () => {
        setCheckParams(CheckParams.PROPAGATE)
        refModal.current?.showModal();
    }

    const onChangeSelectStt = useCallback((e) => {

        value.informationOfBride[0].isOldBrotherBride = e.target.value;

    }, [value]);

    const onChangeSelectCodeArea = useCallback((e) => {

        value.informationOfBride[0].codingRegion = e.target.value;

    }, [value]);

    const onChangeInviteTemp = useCallback((content, delta, source, editor) => {
        setInviteTemp(content)
        value.informationOfBride[0].contentOfInvitation = content
    }, [value])

    const onChangePropagate = useCallback((content, delta, source, editor) => {
        setPropagate(content)
        value.informationOfBride[0].weddingVow = content
    }, [value])

    const onKeyPress = useCallback(() => {

        return

    }, []);


    const renderInput = useCallback(
        (
            ref,
            label,
            placehodel,
            name,
            type,
            maxLength,
            isIcon,
            icon,
            values
        ) => {


            return (
                <div className='item_field_single'>
                    <MyTextInput
                        ref={ref === '' ? refUnderfine : ref}
                        label={label}
                        value={values}
                        name={name}
                        placeHolder={placehodel}
                        type={type}
                        maxLength={maxLength}
                        isIcon={isIcon}
                        icon={icon}
                        styleGroup={'man_inputStyle'}
                        onChangeText={(e) => onChangeText(e.target.value, name)}
                        onKeyPress={onKeyPress}
                    />
                </div>
            )
        },
        [refUnderfine]
    )

    const radioChangeHandlerDeadman = (e) => {
        setRadioDead(e.target.value)
        value.informationOfBride[0].isDisplayGonePeople = e.target.value
    }

    const renderImageUploadSingle = useCallback(
        (
            title,
            images,
            desc,
            allowDrag,
            onChange,
            urlLocal,
            max,
            height,
            icon,
            titleImages,
            maxFileSize,
            loading
        ) => {
            return (
                <div className='uploading_single_img_group'>
                    <h2>{title}</h2>
                    <ImageUpload
                        icon={icon || <ImgUploadIcon />}
                        maxnumber={max || 1}
                        images={images}
                        maxW={'100%'}
                        height={height || 300}
                        desc={desc}
                        onChange={onChange}
                        onSortEnd={onSortEnd}
                        allowDrag={allowDrag}
                        title={titleImages || Languages.text.addonepic}
                        urlLocal={urlLocal}
                        idCreateRespon={idCreateRespon}
                        maxFileSize={maxFileSize}
                        loading={loading}
                    />
                </div>
            )
        },
        [onSortEnd, idCreateRespon]
    )

    return (

        <div className='total_family_one_side'>
            <TitleCreate title={<div><img src={Ic_Bride} alt="Ic_Bride" />{Languages.text.women}</div>} divided={true} />
            <div className='input_fields_control'>
                <div className='place_title_input'>
                    <label>{Languages.inputText.bride}</label>
                </div>
                <div className='group_input_control'>
                    <div className="col-width-input-left">
                        {renderInput(
                            refNameBride,
                            '',
                            'Họ và Tên',
                            NAME_INPUT_BRIDE.name,
                            'text',
                            30,
                            false,
                            false,
                            value.informationOfBride[0].name
                        )}

                        <div className='item_field_single hidden'>
                            <div className='sellect_option'>

                                <select
                                    className='form_sellect_control'
                                    name='form_sellect_stt'
                                    onChange={onChangeSelectStt}
                                >
                                    <option value={'-1'}>{itemLocal ? (itemLocal?.informationOfBride?.isOldBrotherBride ? `Thứ bậc: ${Languages.inputText.top1woman} ` : `Thứ bậc: ${Languages.inputText.notTop} `) : 'Chọn thứ bậc'}</option>
                                    <option value='true'>{Languages.inputText.top1woman}</option>
                                    <option value='false'>{Languages.inputText.notTop}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-width-input-right">
                        {renderInput(
                            refPhoneBride,
                            '',
                            'Số điện thoại',
                            NAME_INPUT_BRIDE.phoneNumberOfBride,
                            'number',
                            10,
                            false,
                            false,
                            value.informationOfBride[0].phoneNumberOfBride
                        )}
                    </div>
                </div>
            </div>

            <div className='input_fields_control'>
                <div className='place_title_input'>
                    <label>{Languages.inputText.father}</label>
                </div>
                <div className='group_input_control'>
                    <div className="col-width-input-left">
                        {renderInput(
                            refNameFather,
                            '',
                            'Họ và Tên',
                            NAME_INPUT_BRIDE.fatherNameOfBride,
                            'text',
                            30,
                            false,
                            false,
                            value.informationOfBride[0].fatherNameOfBride
                        )}
                        <div className="item_field_single">
                            <div className="Input_boxGroupInput__G9mP9 man_inputStyle checkbox">
                                <label className="Input_label__90o4b">{Languages.inputText.death}</label>
                                <div className="Input_formGroup__mXqJL ">
                                    <input type="checkbox" defaultChecked={itemLocal ? itemLocal.informationOfBride.isGoneFatherBride : false} className="Input_form_control__5uYZX inputStyle" onChange={(e) => onChangeText(e.target.checked, NAME_INPUT_BRIDE.isGoneFatherBride)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='input_fields_control'>
                <div className='place_title_input'>
                    <label>{Languages.inputText.mother}</label>
                </div>
                <div className='group_input_control'>
                    <div className="col-width-input-left" style={{ width: '100%', flex: 'auto' }}>
                        {renderInput(
                            refNameMother,
                            '',
                            'Họ và Tên',
                            NAME_INPUT_BRIDE.motherNameOfBride,
                            'text',
                            30,
                            false,
                            false,
                            value.informationOfBride[0].motherNameOfBride
                        )}

                        <div className="item_field_single">
                            <div className="Input_boxGroupInput__G9mP9 man_inputStyle checkbox">
                                <label className="Input_label__90o4b">{Languages.inputText.death}</label>
                                <div className="Input_formGroup__mXqJL ">
                                    <input type="checkbox" defaultChecked={itemLocal ? itemLocal.informationOfBride.isGoneMotherOfBride : false} className="Input_form_control__5uYZX inputStyle" onChange={(e) => onChangeText(e.target.checked, NAME_INPUT_BRIDE.isGoneMotherOfBride)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='enable_show_deadman active'>
                        <div className='label_left'>
                            <label>{Languages.text.displayModeDeceased}</label>
                        </div>
                        <div className='radio_enable'>

                            {renderRadio('none', Languages.common.no, 'none', radioChangeHandlerDeadman, radioDead)}
                            {renderRadio('explanatory', Languages.text.explanatory, 'explanatory', radioChangeHandlerDeadman, radioDead)}

                            {renderRadio('chrysanthemumIcon',
                                <div className='chrysanthemumIcon'>{Languages.text.chrysanthemumIcon}<img src={IcChrysanthemum} /></div>,
                                'chrysanthemumIcon', radioChangeHandlerDeadman, radioDead)}


                        </div>
                    </div>

                </div>
            </div>

            <div className='input_fields_control Select_invitation_template'>

                <div className='place_title_input'>
                    <label>{Languages.text.invite}</label>
                </div>

                <div className='group_textarea_control'>

                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={inviteTemp}
                        onChange={onChangeInviteTemp}
                    />

                    <Button

                        label={Languages.buttonText.invitationTemplate}
                        buttonStyle={BUTTON_STYLES.PINK}
                        textStyle={BUTTON_STYLES.WHITE}
                        isLowerCase
                        onPress={onChangeOpenInviteTemplate}

                    />

                </div>
                {renderModal}
            </div>

            <div className='input_fields_control Select_invitation_template'>

                <div className='place_title_input'>
                    <label>Lời <br /> tuyên thệ</label>
                </div>

                <div className='group_textarea_control'>

                    <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={propagate}
                        onChange={onChangePropagate}
                    />

                    <Button

                        label={"Lời tuyên thệ mẫu"}
                        buttonStyle={BUTTON_STYLES.PINK}
                        textStyle={BUTTON_STYLES.WHITE}
                        isLowerCase
                        onPress={onChangeOpenPropagateTemplate}

                    />

                </div>
            </div>
            <div className='input_fields_control Select_invitation_template'>

                <div className='place_title_input'>
                    <label>Ảnh <br /> tuyên thệ</label>
                </div>

                <div className='group_textarea_control'>

                   

                    <div>
                        {renderImageUploadSingle(
                            <>
                                
                            </>,
                            imagesCover,
                            Languages.text.bigsize,
                            false,
                            onChangeCoverImage,
                            itemLocal?.imgWeddingVow
                        )}
                    </div>

                </div>
            </div>

        </div>

    )
});

export default FamilyBride;
