import React from 'react'

const VolumnOpen = () => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M56 48C56 52.4183 52.4183 56 48 56L0 56L4.89568e-06 -4.89568e-06L48 -6.99382e-07C52.4183 -3.13124e-07 56 3.58172 56 8L56 48Z'
        fill='#A7BDB5'
        fillOpacity='0.6'
      />
      <path
        d='M26.5625 39.2633C26.2028 39.2631 25.8526 39.1475 25.5634 38.9336L25.517 38.8977L19.0862 33.6243H14.1875C13.7399 33.6243 13.3107 33.4465 12.9943 33.13C12.6778 32.8135 12.5 32.3843 12.5 31.9368V24.0625C12.5 23.6149 12.6778 23.1857 12.9943 22.8692C13.3107 22.5528 13.7399 22.375 14.1875 22.375H19.0848L25.5155 17.1015L25.562 17.0657C25.8134 16.8806 26.1112 16.7688 26.4223 16.7429C26.7334 16.717 27.0457 16.7778 27.3243 16.9188C27.6028 17.0597 27.8369 17.2752 28.0003 17.5412C28.1637 17.8072 28.2502 18.1133 28.25 18.4255V37.5744C28.25 38.022 28.0722 38.4512 27.7557 38.7677C27.4393 39.0841 27.0101 39.2619 26.5625 39.2619V39.2633Z'
        fill='white'
      />
      <path
        d='M32.7504 33.625C32.5585 33.6249 32.3698 33.5757 32.2023 33.4821C32.0348 33.3885 31.894 33.2537 31.7933 33.0903C31.6926 32.927 31.6354 32.7406 31.627 32.5489C31.6187 32.3572 31.6595 32.1666 31.7456 31.9951C32.4129 30.6662 32.7511 29.3233 32.7511 28C32.7511 26.6387 32.4234 25.3337 31.7491 24.0111C31.6199 23.7461 31.5999 23.441 31.6935 23.1614C31.787 22.8818 31.9865 22.6501 32.2491 22.5161C32.5117 22.3821 32.8165 22.3566 33.0977 22.445C33.379 22.5334 33.6143 22.7287 33.753 22.9888C34.5926 24.6362 35.0004 26.2752 35.0004 28C35.0004 29.6755 34.5785 31.3595 33.7558 33.0048C33.6622 33.1912 33.5186 33.348 33.3411 33.4575C33.1635 33.567 32.959 33.625 32.7504 33.625Z'
        fill='white'
      />
      <path
        d='M36.125 37C35.9274 37.0002 35.7333 36.9483 35.5621 36.8497C35.391 36.751 35.2488 36.609 35.15 36.4379C35.0511 36.2669 34.9991 36.0728 34.999 35.8753C34.999 35.6777 35.051 35.4836 35.1497 35.3125C36.4765 32.9957 37.25 31.0551 37.25 28C37.25 24.8978 36.4808 22.9713 35.1526 20.691C35.0155 20.4339 34.9835 20.1337 35.0633 19.8535C35.1432 19.5732 35.3286 19.3349 35.5806 19.1887C35.8327 19.0425 36.1316 18.9997 36.4145 19.0694C36.6974 19.1392 36.9422 19.3159 37.0974 19.5625C38.5803 22.105 39.5 24.4057 39.5 28C39.5 31.541 38.5817 33.8528 37.1009 36.4375C37.002 36.6088 36.8598 36.751 36.6884 36.8497C36.5171 36.9485 36.3227 37.0003 36.125 37Z'
        fill='white'
      />
    </svg>
  )
}

export default VolumnOpen
